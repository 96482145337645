html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #131313;
  color: rgba(255, 255, 255, 0.87);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas {
  height: calc(100vh + 200px);
  width: 100%;
  position: relative;
  background: transparent;
  z-index: 0;
}

.app-header {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 1rem;
}

.font--primary {
  font-family: 'Georgia', serif;
  font-size: 4rem;
  line-height: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.font--link {
  text-decoration: none;
  color: #fff;
  opacity: 0.54;
}

.font--link:hover {
  opacity: 0.87;
}

.btn--primary {
  display: inline-block;
  height: auto;
  text-transform: uppercase;
  padding: 1rem;
  margin: 0 1rem;
  font-size: 1.25rem;
  font-weight: bolder;
  border: 2px solid #fff;
  border-radius: 0.5rem;
  background: transparent;
  color: #fff;
  cursor: pointer;
}

.btn--primary:hover {
  background: rgba(255, 255, 255, 0.54);
}

@media all and (max-width: 767px) {
  canvas {
    min-height: 50vh;
    top: -10vh;
  }

  .font--primary {
    font-size: 2.4rem;
    line-height: 1.6rem;
  }
}
